/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import third party library styles here.
/* ----------------------------------------------------------------------------------------------------- */

/* FullCalendar */
/* @import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';
@import '~@fullcalendar/timegrid/main.css';
@import '~@fullcalendar/list/main.css';
 */
/* Perfect scrollbar */
//@import '~perfect-scrollbar/css/perfect-scrollbar.css';

/* Quill */
//@import '~quill/dist/quill.snow.css';

/* toastr*/
@import '~ngx-toastr/toastr';