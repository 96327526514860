
/* 1. Components */
@import 'components/example-viewer';
@import 'components/input';

/* 2. Overrides */
@import 'overrides/angular-material';
@import 'overrides/fullcalendar';
//@import 'overrides/highlightjs';
//@import 'overrides/perfect-scrollbar';
//@import 'overrides/quill';
