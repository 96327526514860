/***********************************************************************************/
/*************************** WizHob V2 scss start ************************************/
/***********************************************************************************/

/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
// .cdk-overlay-container{
//   // top: 72% !important;
//   // left: -16px !important;
//   height: auto;
// }

@import url("../assets/fonts/inter/inter.css");
@import 'https://fonts.googleapis.com/icon?family=Material+Icons';


:root {
  // --wizhob-primary-color: var(--wizhob-primary-color);
  --wizhob-primary-color: rgb(12, 27, 59);
  --wizhob-secondary-color: rgb(255, 95, 56);
  --wizhob-third-color: rgb(255, 95, 56);
}
.primary-color{
  --wizhob-secondary-color: rgb(255, 95, 56);
}
.text-gray {
  color: gray;
}
.bg-wizhob-primary {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(12, 27, 59, var(--tw-bg-opacity)) !important;
}
.mat-accordion .mat-expansion-panel {
  margin-bottom: 8px;
  border-radius: 8px !important;
  border: 1px solid #b6b6b6;
  box-shadow: none !important;
  &:hover {
    box-shadow: none !important;
  }
}

search {
  .mat-form-field.mat-form-field-appearance-fill {
    .mat-form-field-wrapper {
      /* Border color */
      .mat-form-field-flex {
        --tw-bg-opacity: 1 !important;
        background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
      }
    }

    /* Focused */
    &.mat-focused,
    &.mat-form-field-can-float {
      .mat-form-field-wrapper {
        /* Background color */
        .mat-form-field-flex {
          --tw-bg-opacity: 1 !important;
          background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
          border-color: #b5bdd3 !important;
          @apply text-black font-semibold #{'!important'};
        }
      }
    }

    /* Focused and valid fields */
    &.mat-focused:not(.mat-form-field-invalid) {
      .mat-form-field-wrapper {
        /* Border color */
        .mat-form-field-flex {
          --tw-border-opacity: 1 !important;
          border-color: rgba(100, 116, 139, var(--tw-border-opacity)) !important;
          .mat-input-element {
            caret-color: #000;
          }
        }
      }
    }
  }

  .bar-input {
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
    @apply text-black font-semibold #{'!important'};
  }
}

fuse-horizontal-navigation,
fuse-mobile-navigation {
  .fuse-horizontal-navigation-wrapper,
  .fuse-mobile-navigation-wrapper {
    /* Basic - When item active (current link) */
    fuse-horizontal-navigation-basic-item,
    fuse-mobile-navigation-basic-item {
      .fuse-horizontal-navigation-item-active,
      .fuse-horizontal-navigation-item-active-forced,
      .fuse-mobile-navigation-item-active,
      .fuse-mobile-navigation-item-active-forced {
        .fuse-horizontal-navigation-item-icon,
        .fuse-mobile-navigation-item-icon {
          @apply text-white #{'!important'};
        }
      }
    }
  }
}

.chatbox-height {
  height: calc(100vh - 246px);
}
body.light .mat-primary .mat-option.mat-selected:not(.mat-option-disabled),
body .light .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #000000;
}

ngx-editor {
  .NgxEditor__Content {
    ul,
    ol {
      list-style: revert;
      margin: revert;
      padding: revert;
    }
  }
}

/***********************************************************************************/
/*************************** WizHob V2 scss end ************************************/
/***********************************************************************************/

// Note: don't Remove Above Code

@font-face {
  font-family: "password";
  font-style: normal;
  font-weight: 400;
  src: url(../assets/fonts/password.ttf);
  font-display: optional;
}

* {
  font-size: 100%;
  font-family: Inter;
  font-weight: 700;
}

html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  font-family: Inter, "Helvetica Neue", sans-serif;
  font-display: optional;
}

a {
  &:hover {
    text-decoration: none !important;
  }
}

.theme-primary-bg {
  background-color: var(--wizhob-primary-color);
}
.invalid-feedback {
  display: none;
  width: 100%;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.mat-paginator-page-size-label {
  position: relative;
  top: -1px;
}

.btn-orange {
  background-color: var(--wizhob-secondary-color) !important;
  color: #fff !important;
  padding: 10px;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  border-radius: 5px !important;
}
.brd-btn {
  border: 1px solid var(--wizhob-secondary-color) !important;
  padding: 10px 40px;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  border-radius: 5px !important;
}

.teach-on-wizhob-btn {
  border: 1px solid var(--wizhob-secondary-color) !important;
  // padding: 10px 40px;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  border-radius: 5px !important;
}

.gen-custom-btn {
  background-color: var(--wizhob-primary-color) !important;
  padding: 13px 16px;
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 24px;
  text-decoration: none;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  color: #ffffff;
  box-shadow: 0 0 15px 0 rgb(0 0 0 / 48%);
  &:hover {
    transition: 1s;
    background-color: #ffffff !important;
    color: var(--wizhob-primary-color) !important;
  }
}
.course-wrapper-2:hover .course-cart {
  opacity: 1;
  visibility: visible;
}
.course-card {


  border-radius: 4px !important;
	border: 1px rgb(208, 208, 208) solid;
	//box-shadow: none !important;
	padding:10px;

  box-sizing: border-box;
  max-width: 100%;
  animation: scl 0.5s ease-in-out;
  transform-origin: left center;
  margin-bottom: 5px;
  margin-left: 1px;
  display: block;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.7), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  .card-title {
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 1px;
    font-size: 1em !important;
		height: 50px;
    @media (max-width: 767px) {
      font-size: 17px;
      padding-top: 10px;
    }
  }
  .course-image {
    position: relative;
    overflow: hidden;
  }
  .course-image img {
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%;
    border-radius: 10px 10px 0px 0px;
    min-height: 250px;
    height: 250px;
  }
  .imageoverlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    opacity: 1;
    // background: linear-gradient(
    //   1turn,
    //   hsla(0, 0%, 100%, 0) 17%,
    //   rgba(68, 67, 67, 0.4) 57%,
    //   rgba(0, 0, 0, 0.7411764705882353)
    // );
    z-index: 2;
    cursor: pointer;
    border-radius: 8px;
    .dateholder {
      background-color: white;
      position: absolute;
      left: 10px;
      top: 10px;
      padding: 10px;
      border-radius: 10px 10px 0px 0px;
      color: black;
      font-size: 18px;
      inline-size: 150px;
      overflow-wrap: break-word;
      text-align: center;
      line-height: 34px;
      span {
        font-size: 14px;
      }
    }
    
    .timeholder {
      background-color: var(--wizhob-secondary-color);
      position: absolute;
      display: block;
      left: 10px;
      top: 50px;
      padding: 7px 0px;
      border-radius: 0px 0px 10px 10px;
      color: white;
      font-size: 11px;
      inline-size: 150px;
      overflow-wrap: break-word;
      text-align: center;
      line-height: 15px;
      span {
        font-size: 8px;
        display: block;
      }
    }
  }
  .GMTcolor{
    background-color: 1px bold rgb(255, 95, 56);
  }
  .topline {
    width: 100%;
    display: block;
    position: relative;
  }
  .card-title {
    width: 100%;
    height: 22px;
    line-height: 1.1em;
    padding: 0.5em 0em;
    font-size: 1.2em;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal !important;
  }
  .card-share {
    width: 10%;
    top: 10px;
    right: 10px;
  }
  .card-subtitle {
    width: 90%;
    display: inline-block;
  //  padding: 0.3em 0.5em;
    font-size: 1.1em;
    // font-style: italic;
  }
  .card-desc {
    padding:  0.7em;
    display: block;
    border-bottom: 1px solid rgba(128, 128, 128, 0.3);
    height: 80px;
    overflow: hidden;
    font-size: 0.8em;
    line-height: 1.2em;
  }
  .card-action-readMore {
    margin: 1em 0 1em 1em;
    height: 3em;
    font-size: 1em;
    background: var(--wizhob-primary-color);
    border: none;
    font-weight: light;
    color: #fff;
    position: relative;
    overflow: hidden;
    outline: none;
    border-radius: 10px;
    padding: 1em;
    line-height: 1em;
  }
  .card-action-readMore:hover {
    background: var(--wizhob-secondary-color);
    transition: all 0.1s ease-in;
  }
  .card:hover {
    box-shadow: 7px 7px 15px 2px rgba(0, 0, 0, 0.5);
    transition: box-shadow 0.3s ease-in;
  }

  .divleft {
    float: left;
    width: 76%;
    line-height: 1em;
  }
  .divright {
    float: right;
    height: 34px;
    width: 49%;
    position: relative;
    margin: 12px 10px;
  }
  .middler {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    line-height: 1.2em;
    left: 25%;
  }

  .course-image:hover img {
    -webkit-transform: scale(1.3) rotate(0.01deg);
    transform: scale(1.3) rotate(0.01deg);
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
  }
  .course-cart {
    background: #ffffff;
    padding: 15px 25px;
    box-shadow: 0 0 70px #0f264733;
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    visibility: hidden;
    top: 1px;
    right: 0;
    border: 1px grey solid;
    border-radius: 20px 20px 0px 0px;
    transition: 0.3s;
    z-index: 11;
    left: 50%;
    transform: translate(-50%);
  }
  @media (max-width: 575px) {
    .course-cart {
      display: none;
    }
  }
  .course-info-wrapper h3 {
    overflow: hidden;
    font-size: 18px;
    margin-bottom: 5px;
    line-height: 1.1;
    text-overflow: ellipsis;
    display: block;
    -webkit-line-clamp: 2;
    height: 55px !important;
    -webkit-box-orient: vertical;
  }
  .category-color {
    font-size: 15px;
    font-weight: 600;
    background: #6f19c5;
    padding: 2px 10px;
    color: #fff;
    margin-bottom: 15px;
    display: inline-block;
    height: 26px;
    border-radius: 3px;
  }
  .cart-info-body h3:hover {
    color: #2467ec;
  }
  .cart-lavel span {
    color: #2467ec;
    font-size: 13px;
    font-weight: 600;
  }
  .cart-lavel h4 {
    line-height: 1.1;
    font-size: 15px;
    font-weight: 800;
    color:grey;
  }
  .cart-lavel h5 {
    font-size: 13px;
    font-weight: 400;
    color: #575757;
    line-height: 20px;
  }

  .cart-lavel p {
    margin: 2px 0;
    font-size: 13px;
    font-weight: 600;
    color: #575757;
  }
  .info-cart-text ul {
    margin-bottom: 30px;
  }
  .info-cart-text ul li {
    margin-bottom: 5px;
  }
  .info-cart-text ul i {
    margin-right: 15px;
    color: #2467ec;
  }
  .course-action {
    display: flex;
    justify-content: space-between;
    gap: 15px;
  }
  .course-action .view-details-btn {
    flex-grow: 1;
  }
  
}

.main-course-info-custom {
  .form-container {
    mat-form-field {
      width: 100%;
    }
    .form-group {
      mat-label {
        font-size: 16px;
        margin-bottom: 7px;
        display: inline-block;
        strong {
          font-size: 12px;
        }
      }
    }
  }
  .mat-form-field-wrapper {
    padding-bottom: 0;
  }
  .ng-select {
    .ng-select-container {
      padding: 1em 0 1em 0 !important;
      height: 50px !important;
      background: transparent !important;
    }
  }
  .form-group.timepics {
    .mat-form-field-appearance-outline {
      .mat-form-field-infix {
        padding-top: 0 !important;
      }
    }
  }
}

.course-listing-box {
  .inside-course-list-box {
    .discription {
      mat-expansion-panel {
        border: 0;
        box-shadow: unset;
        mat-expansion-panel-header {
          padding: 0;
        }
      }
    }
  }
  .mat-expansion-panel-header {
    height: 22px !important;
    padding: 26px !important;
    .mat-expansion-panel-header-title {
      span {
        position: absolute;
        right: 0px;
        top: 2px;
      }
    }
  }
  .mat-expansion-panel-content {
    .mat-expansion-panel-body {
      padding: 0 24px 16px !important;
    }
  }
  .mat-expansion-panel {
    .arrow-up {
      display: none;
    }
  }
  .mat-expansion-panel.mat-expanded {
    .arrow-up {
      display: block;
    }
    .arrow-down {
      display: none;
    }
  }
}

.main-title {
  line-height: normal;
}

.main-content {
  .mat-form-field.mat-focused {
    .mat-form-field-outline {
      color: #000000;
    }
    .mat-form-field-label {
      color: #000000;
    }
  }
  .form-group {
    .mat-form-field-subscript-wrapper {
      bottom: -15px;
      top: unset !important;
    }
  }
}
.mat-horizontal-stepper-header-container {
  margin-bottom: 30px;
}
.full-width-center-custom-btn {
  width: 40% !important;
  margin: 20px auto 20px auto;
}

.gradientUnderLine {
  width: 140px;
  margin-bottom: 10px;
  height: 6px;
  align-self: center;
  border-radius: 5px;
  background-color: var(--wizhob-secondary-color);
}

.benefitGradient {
  width: 52px;
  margin-top: 6px;
  height: 3px;
  background-color: #f4344c;
}

.benefitFooterGradient {
  width: 52px;
  margin-top: 6px;
  height: 3px;
  background-color: #2874f0;
}

.f1 {
  font-size: 3em;
}

.f2 {
  font-size: 2.625em;
}

.f3 {
  font-size: 1.75em;
}

.f4 {
  font-size: 1.5em;
}

.f5 {
  font-size: 1.25em;
}

.f6 {
  font-size: 1em;
}

.f7 {
  font-size: 0.875em;
}

.f8 {
  font-size: 0.75em;
}

.f10 {
  font-size: 0.625em;
}

.f14 {
  font-size: 0.875em;
}

.f20 {
  font-size: 1.25em;
  font-size: 1.25em;
}

video {
  width: 100%;
  outline: 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.24);
}

.heading {
  text-align: center;
  text-transform: uppercase;
  margin: 0;
  font-weight: 600;
}

.location {
  align-items: center;
  display: flex;
  min-width: 250px;
  text-decoration: none;
  max-width: 250px;
  margin-bottom: 20px;
}

.center {
  align-items: center;
  justify-content: center;
}

.alignCenter {
  align-items: center;
}

.relative {
  position: relative;
}

.justifyCenter {
  justify-content: center;
}

.bold {
  font-weight: 700;
}

.spaceBetween {
  justify-content: space-between;
  justify-content: space-between;
}

.extraBold {
  text-shadow: 0 1px, 1px 0, 1px 1px;
}

.spaceAround {
  justify-content: space-around;
}

#expandedView {
  display: none;
}

nav.bg-custom {
  background-color: var(--wizhob-primary-color);
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

#header {
  .brand {
    margin: 10px 0;
    transition: all 0.3s linear;
  }
}

.navbar-nav {
  .nav-item {
    .nav-link {
      color: #ffffff;
      font-size: 1em;
      margin-right: 6px;
      -webkit-transition: all 0.25s linear;
      -ms-transition: all 0.25s linear;
      transition: all 0.25s linear;
      i.fas {
        color: var(--wizhob-secondary-color);
      }
    }
  }
  .dropdown {
    position: relative;
  }
  .dropdown-item {
    &:hover {
      background: var(--wizhob-primary-color);
      color: #fff;
    }
  }
  .nav-item.dropdown {
    .dropdown-menu {
      background: #831111;
      min-width: auto;
      top: 97%;
      width: calc(100% - 71px);
      left: 55px;
      border-radius: 0;
      border: 0;
      margin: 0;
      padding: 0;
    }
  }
  .search_link.nav-item.dropdown {
    .dropdown-menu {
      width: 300px;
      left: auto;
      right: 0;
      padding: 10px 10px;
      background: #831111;
      margin: 0;
      border: 0;
    }
  }
}

nav.navbar {
  padding: 10px 0px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
}

.mobile-view {
  display: none;
}

.coyprightText {
  display: none;
}

.formStyle {
  align-items: center;
  border-radius: 4px;
  border: solid 1px #2e276e;
}

.inputStyle {
  border: 0;
  outline: 0;
  flex: 1;
  border-radius: 4px;
  margin: 0;
  padding: 0 10px;
}

.mediaContainer {
  width: 95%;
  align-self: center;
  max-height: 300px;
  padding-top: 12px;
  padding-left: 12px;
  padding-bottom: 12px;
  margin-top: 40px;
}

.mediaContentContainer {
  justify-content: flex-start;
  text-align: left;
  padding: 20px;
  margin-right: 15px;
  background: #fff;
  border-radius: 15px;
  cursor: grab;
}

.mediaContent {
  width: "100%";
  margin-left: 20px;
  padding-bottom: 10px;
  padding-right: 50px;
  border-bottom: 1px solid #979797;
}
.aboutUsHeading {
	display: flex;
	padding-top: 60px;
	flex-direction: column;
	align-items: center;
	background: white;
}
.aboutHeadingText {
	text-transform: uppercase;
	color: var(--wizhob-primary-color);
	font-size: 2.625em;
	font-weight: 600;
}
.aboutHeadingGradient {
	width: 140px;
	height: 6px;
	margin-top: 10px;
	margin-bottom: 24px;
	border-radius: 5px;
	background-image: linear-gradient(92deg, #303395, #1b9dff);
}

.card {
  min-width: 100px;
  text-align: left;
  overflow: hidden;
  margin-right: 22px;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.24);
  border-style: solid;
  border-width: 1px;
  padding: 24px 28px;
  min-height: 184px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.avatarContainer {
  margin-top: 16px;
  align-items: center;
}

.reviewAvatar {
  height: 60px;
  width: 60px;
  border-radius: 50%;
}

.starYellow {
  color: #f5a623;
}

.ml_48 {
  margin-left: 48px;
}

.animated {
  -webkit-animation: action 1s infinite alternate;
  animation: action 1s infinite alternate;
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

footer {
  margin-top: auto;
  vertical-align: bottom;
}

.footer {
  padding: 10px;
  background-color: var(--wizhob-primary-color);
}

.navigateFooter {
  padding: 25px 100px;
  background-color: var(--wizhob-primary-color);
}

.footerHeading {
  flex: 1;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
}

.footerLinkContainer {
  flex: 1;
  margin-top: 12px;
  padding: 0;
  > ul {
    padding: 0 !important;
    margin: 0 !important;
    list-style-type: none;
  }
  > ul > li > a,
  > ul > li > a > span {
    font-weight: 200;
    font-size: 14px;
    line-height: 1;
  }
}

.footerlink {
  font-size: 14px;
  color: #fff;
  line-height: 1.86;
  text-decoration: none;
}

.footerSeparator {
  opacity: 0.8;
  background-color: var(--wizhob-primary-color);
  height: 1px;
}

.footerLogo {
  margin-left: 50px;
  height: 24px;
}

.copyRightText {
  color: #fff;
  font-size: 14px;
}

// .plansContainer {
//   margin: 12px;
// }

// .productContainer {
//   background: #fff;
//   padding-left: 30px;
//   padding-top: 20px;
//   padding-right: 16px;
//   padding-bottom: 15px;
// }

.priceCardContainer {
  flex: 1;
  align-items: center;
  justify-content: space-between;
}

.priceCardContainer {
  .card_pricing {
    margin-right: 16px;
    margin-top: 18px;
    overflow: hidden;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    width: 100%;
    max-width: 320px;
    position: relative;
    text-transform: uppercase;
    cursor: pointer;
    padding: 0;
  }
}

.priceCardDescription {
  padding-top: 10px;
  font-size: 0.75em;
  font-weight: 400;
  text-transform: none;
}

.cardHeader {
  justify-content: space-between;
  flex: 1;
  background-color: #f2f2f2;
  color: var(--wizhob-primary-color);
  font-weight: 700;
  padding-top: 16px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
}

.priceContainer {
  padding: 12px 20px;
  justify-content: space-between;
  background: #fff;
  text-transform: none;
}

.buttonStyle {
  min-width: 100px;
  padding: 15px 25px;
  display: flex;
  font-size: 1em;
  margin-top: 16px;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  color: #9b9b9b;
  margin-right: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
  border: solid 1px #9b9b9b;
  font-weight: 700;
  cursor: pointer;
}

.selectedButtonStyle {
  background-color: #2874f0;
  color: #fff;
}

.heading_title {
  font-size: 1em;
  line-height: 1.44;
  letter-spacing: normal;
  color: var(--wizhob-primary-color);
  font-family: "gothambook";
}

.headingGradient {
  width: 35px;
  height: 4px;
  border-radius: 2.5px;
  background-image: linear-gradient(96deg, #303395, #1b9dff);
}

.headingLine {
  flex: 1;
  height: 2px;
  background-color: #e3e3e3;
}

.selectedCardHeader {
  background-color: #2874f0;
  color: #fff;
}

.tickImageContainer {
  height: 22px;
  position: absolute;
  right: 16px;
  background: #fff;
  width: 22px;
  border: solid 1px #27d671;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  color: #27d671;
}

.basicContainer {
  padding-top: 20px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: initial;
}

.checkoutContainer {
  background: #fff;
  display: flex;
  position: -webkit-sticky;
  height: 88vh;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
  position: sticky;
  top: 65px;
}

.underBar {
  width: 31px;
  height: 2px;
  margin-top: 5px;
  background-color: #9b9b9b;
  border-radius: 2px;
}

.selectedUnderBar {
  background-color: rgba(255, 255, 255, 0.48);
}

.dot5 {
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background: #000;
}

.basicDescription {
  font-size: 0.875em;
}

.basicFitpass {
  margin-bottom: 12px;
}

.orderDetailContainer {
  padding: 20px;
}

.orderSeparator {
  height: 1px;
  background-color: #e3e3e3;
  height: 1px;
  background-color: #e3e3e3;
}

.checkBox {
  height: 20px;
  width: 20px;
  max-height: 20px;
  min-width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border: solid 1px #27d671;
  color: #27d671;
}

.coupanButtonContainer {
  border-radius: 6px;
  overflow: hidden;
  margin-top: 15px;
  border: 1px solid #d3d3d3;
}

// .applyButton {
//   background-color: #2874f0;
//   font-size: 0.875em;
//   letter-spacing: 2.3px;
//   color: #fff;
//   display: flex;
//   flex: 1;
//   font-weight: 700;
//   align-items: center;
//   justify-content: center;
//   cursor: pointer;
// }

.plansHeadingContainer {
  align-items: center;
  font-weight: 400;
  margin-top: 16px;
}

.priceDescription {
  color: #302c57;
  flex: 1;
  font-size: 14px;
}

.priceStyle {
  font-weight: 700;
  color: #302c57;
}

.bottomPriceContainer {
  bottom: 0;
  right: 0;
  left: 0;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 -1px 2px 0 rgba(0, 0, 0, 0.16);
}

// .formDescription {
//   font-size: 0.875em;
//   color: var(--wizhob-primary-color);
//   line-height: 1.43;
//   display: inline-block;
// }

.signUpButton {
  cursor: pointer;
  padding: 16px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: #2874f0;
  color: #fff;
  font-weight: 700;
  font-size: 1em;
  margin-top: 20px;
  width: 100%;
}

.field-group {
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.23);
  padding: 14px;
  display: flex;
  background: #fff;
  align-items: center;
  border-radius: 4px;
  .label {
    display: inline-block;
    position: absolute;
    font-size: 0.875em;
    transition: all 150ms ease-in;
    color: #676767;
  }
}

.floating-label1 {
  -webkit-appearance: none !important;
  border: 0;
  outline: 0;
  font-size: 18px;
  width: 100%;
  color: var(--wizhob-primary-color);
  font-weight: 500;
  padding: 0;
}

::-webkit-input-placeholder {
  font-size: 15px;
  font-weight: 400;
}

.label.field-active {
  transform: translateY(-25px);
    top: 10px;
    font-size: 0.8em;
    font-weight: 700;
    letter-spacing: 1px;
    color: rgb(12, 27, 59);
    color: #fff;
    background: #5f5f5f;
    border-radius: 6px;
    padding: 2px 10px;
  @media (max-width: 767px) {
    font-size: 9px;
    padding-top: 9px;
  }
}

.grayheadingUnderline {
  border: solid 1px #e3e3e3;
}

.blueUnderline {
  width: 36.3px;
  height: 4.2px;
  border-radius: 2.5px;
  background-image: linear-gradient(96deg, #303395, #1b9dff);
}

.underLineContainer {
  margin-top: 6px;
  margin-bottom: 10px;
}

.headingTitle {
  font-size: 1em;
  font-weight: 700;
  letter-spacing: 2px;
}

.contentContainer {
  background: #fff;
}

.navigatorLink {
  text-decoration: none;
  color: var(--wizhob-primary-color);
}


.question {
  font-weight: 700;
  font-size: 1em;
  margin-top: 20px;
}

.answer {
  margin-top: 8px;
  font-size: 1em;
}

.modal {
  position: absolute !important;
  overflow: inherit !important;
}

.visible-mobile {
  display: none;
}

.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fa-map:before {
  content: "\f279";
}

.error-msg {
  color: red;
}

@media (max-width: 820px) {
  .navbar-toggler {
    &:focus {
      outline: 0;
      border: 0;
    }
    .navbar-toggler-icon {
      background-image: url(../assets/img/open-menu.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }
  .navbar.bg-custom {
    .container {
      padding-right: 15px;
      padding-left: 15px;
    }
  }
  footer.desktop-view {
    display: block !important;
  }
  footer {
    .navigateFooter {
      padding: 15px;
      .flex {
        display: inline-block;
        width: 100%;
      }
    }
  }
  .workoutImageContaier {
    img {
      object-fit: cover;
    }
  }
  .full-width-center-custom-btn {
    width: 70% !important;
  }
  .visible-mobile {
    display: block;
    .app-icons {
      text-align: center;
      margin: 0 auto;
      padding-top: 35px;
      .flex {
        align-items: center;
        justify-content: center;
      }
    }
  }
  .hidden-mobile {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 959px) {
  .navbar-nav {
    .nav-item {
      .nav-link {
        margin-right: 0px !important;
      }
    }
  }
  .full-width-center-custom-btn {
    width: 50% !important;
  }
}

.mat-tab-label {
  background-color: #c7c7c7 !important;
  opacity: 1 !important;
  color: black !important;
}
.mat-tab-group .mat-tab-header .mat-tab-label-container .mat-tab-list .mat-tab-labels .mat-tab-label.mat-tab-label-active,
.mat-tab-label.mat-tab-label-active {
  background-color: var(--wizhob-secondary-color) !important;
  opacity: 1 !important;
  color: white !important;
  border: 2px var(--wizhob-secondary-color) solid;
}

.mat-tab-label-container {
  border-radius: 6px 6px 0px 0;
}

.mat-tab-group {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.24);
}

.table-responsive {
  overflow-x: hidden !important;
}

.cdk-global-overlay-wrapper {
  pointer-events: all !important;
  position: absolute;
  z-index: 1000;
  overflow: auto;
  pointer-events: auto;
}

// .sharedropper button {
//   margin: 0.2em;
//   border-radius: 0.4em;
//   border: none;
// }

.sharing::after {
  display: none !important;
}

.e-schedule .e-vertical-view.e-timescale-disable .e-appointment .e-subject {
  font-size: 10px;
}

.e-schedule .e-vertical-view.e-timescale-disable .e-appointment .e-time,
.e-schedule .e-vertical-view.e-timescale-disable .e-appointment .e-location {
  font-size: 9px;
}

//new css add By MJ

/* Rounded design */
.bg-primary-bg{
  background-color: var(--wizhob-primary-color) !important;
}
.bg-secondary{
  background-color: var(--wizhob-secondary-color) !important;
}
.wizhob-rounded-button {
  padding: 0 50px !important;
  border-radius: 9999px !important;
  background-color: var(--wizhob-secondary-color) !important;
  color: #fff !important;
}
// use for mat-dialog
::ng-deep {
  .mat-dialog-container {
    padding: 15px 0;
  }
  .mat-dialog-header {
    border-bottom: 1px solid #e9ecef;
    padding: 0 24px;
  }
  .mat-dialog-title {
    margin: 0;
  }
  .mat-dialog-content {
    margin: 0;
    max-height: calc(100vh - 10em);
  }
  .mat-dialog-content {
    padding: 24px;
  }
  mat-dialog-actions {
    padding: 0 24px;
  }
}

.fuse-vertical-navigation-item-title span,
.fuse-vertical-navigation-item-icon {
  color: white !important;
}
.owl-carousel {
 // min-height: 200px;
  max-height: 500px;
  opacity: 1 !important;
  .slide-owl-wrap:not(:first-child) {
    display: none;
  }
}
.owl-carousel .nav-btn {
  height: 47px;
  position: absolute;
  width: 26px;
  cursor: pointer;
  top: 30% !important;
}
.owl-theme .owl-nav [class*="owl-"] {
  background: none;
}
.owl-carousel .owl-prev.disabled,
.owl-carousel .owl-next.disabled {
  pointer-events: none;
  opacity: 0.2;
}

.owl-carousel .prev-slide {
  background: url(../assets/img/nav-icon.png) no-repeat scroll 0 0;
  left: -33px;
}
.owl-carousel .next-slide {
  background: url(../assets/img/nav-icon.png) no-repeat scroll -24px 0px;
  right: -33px;
}
.owl-carousel .prev-slide:hover {
  background-position: 0px -53px;
}
.owl-carousel .next-slide:hover {
  background-position: -24px -53px;
}

input:read-only {
  color: rgb(148, 163, 184);
}
.main-course-list-custom {
  .main-title {
    text-align: left;
    margin-top: 0px;
    font-weight: 600;
    font-size: 1.2em;
    padding: 10px;
    color: var(--wizhob-secondary-color);
    border-bottom: 2px solid #eeeeee;
    margin-bottom: 30px;
    position: relative;
    line-height: normal;
  }
}

.course-long-discription {
  ol {
    list-style: auto !important;
    line-height: 1.2;
  }
  ul {
    list-style: disc !important;
  
  }
  li{
    margin: 0 0 3px 0;
  }
  h2{
    font-size: 18px;
  }
  a {
    color: blueviolet !important;
    text-decoration: underline !important;
  }
  blockquote {
    border-left: 3px solid #ddd;
    margin-left: 0;
    margin-right: 0;
    padding-left: 1rem;
  }
}
.top-header{
  background-color: var(--wizhob-primary-color);
}
@keyframes autoplay2 {
	0% {
		top: 100%;
	}
	4% {
		top: 0%;
	}
	33.33% {
		top: 0%;
	}
	37.33% {
		top: -100%;
	}
	100% {
		top: -100%;
	}
}
#slideset2 {
	height: 3vw;
	position: relative;
	overflow: hidden;
  align-items: center;
	>* {
		position: absolute;
		top: 100%;
		left: 10%;
    right:10%;
		animation: 12s autoplay2 infinite ease-in-out;
		&:nth-child(1) {
			animation-delay: 0s;
		}
		&:nth-child(2) {
			animation-delay: 4s;
		}
		&:nth-child(3) {
			animation-delay: 8s;
		}
	}

  >div{
    width: 80%; 
  
  }
  
 
}

@media (max-width: 760px) {
  .hobReq{
    width: 100%;
    text-align: center;
    justify-content: center;
  }
  #slideset2 {
    height: 1em;
    
    >* {
      
      left: 0;
      }
  
    >div{
      width: 100%; 
    
    }
    
   
  }

  }
.locationSelect {
  background: white;
  color: #000;
  padding: 4px 10px;
  -webkit-border-radius: 8px;
-moz-border-radius: 8px;
border-radius: 8px;
font-size: 14px;

}

 .testClass{
   .mat-option{
    height: 2em !important;
  }
} 

// .dtwidget-chatmodal-footer{
//   display: none !important;
// }
.bginner{
  --tw-bg-opacity: 1 !important;
		background-color: rgba(241, 245, 249, var(--tw-bg-opacity)) !important;
    min-height: 40vw;
}
.main-title {
	margin-top: 20px;
	font-size: 2.625em;
	// color: var(--wizhob-primary-color);
	border-bottom: 2px solid #d2cfcf;
	padding-bottom: 22px;
	position: relative;
}
.tablabel {
  padding: 0 4px;
}
